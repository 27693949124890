import { isProduction } from '~/helpers/functions'

export function generateMeta ({
  title = 'Compare Club',
  ogTitle,
  description,
  canonical,
  ogDescription,
  ogImage,
  ogUrl,
  ogType = '',
  meta = [],
  noIndex = false,
  noFollow = false
}, isPageFunnel = false) {
  const $route = useRoute()
  const path = $route.path
  const fullPath = `${CC_DOMAIN}${path}`

  const url = ogUrl ?? fullPath

  canonical = canonical ?? ogUrl ?? fullPath

  const robots = (() => {
    const flags = []

    if (noIndex || !isProduction()) {
      flags.push('noindex')
    }

    if (noFollow || isPageFunnel || !isProduction()) {
      flags.push('nofollow')
    }

    return flags.join(', ')
  })()

  useHead({
    title: () => title,
    link: [
      {
        rel: 'canonical',
        href: canonical
      }
    ],
    meta: [
      {
        name: 'description',
        content: () => description
      },
      ...meta
    ]
  })

  useServerSeoMeta({
    ogSiteName: 'Compare Club',
    robots,

    title,
    ogTitle: ogTitle || title,

    description,
    ogDescription: ogDescription || description,
    ogUrl: url,
    ogType,
    ogImage: ogImage || OG_DEFAULT_IMAGE
  })
}
